export default {
  'GMT Daylight Time': 'DST',
  'GMT Standard Time': 'GST',
  'CST (Mexico': 'CST',
  'CDT (Mexico': 'CDT',
  'US MST': 'MST',
  'Mid-Atlantic Daylight Time': 'MST',
  'SA Western Standard Time': 'SAWST',
  'Bolivia Time': 'BOT',
  'Arabian Standard Time': 'AST',
  'Singapore Standard Time': 'SGT',
  'Gulf Standard Time': 'GST',
  'GMT-06:00': 'CST',
  'GMT-05:00': 'EST',
  'East Africa Time': 'EAT',
  'Brasilia Standard Time': 'BST',
  'Horário Padrão de Brasília': 'BST',
};
