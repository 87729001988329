<template>
  <div class="table-responsive mb-0">
    <!-- TODO Make table sortable for all fields     -->
    <p>Showing 1 - {{ incidents_count }} out of {{ incidents_count }}.</p>
    <div class="row mb-3">
      <div class="col-7">
        <h5>
          <b-badge class="mx-2" variant="success">CAD</b-badge>
          <b-badge class="mx-2" variant="primary">CAD Preplan</b-badge>
          <b-badge class="mx-2" variant="secondary">Rave</b-badge>
          <b-badge class="mx-2" variant="danger">High Priority Incident</b-badge>
          <b-badge class="mx-2" variant="warning">Shared With Me
            <i class="bx bx-down-arrow-alt"></i></b-badge>
          <b-badge class="mx-2" variant="warning">Shared By Me
            <i class="bx bx-up-arrow-alt"></i></b-badge>
        </h5>
      </div>
      <div class="col-3 btn-toolbar">
        <div class="input-group">
          <div class="input-group-text" id="search-icon">
            <i class="bx bx-search"></i></div>
          <input type="text" class="form-control" v-model="searchInput"
                 placeholder="Search..." @input="filterIncidents"
                 aria-label="Search Input" aria-describedby="search-icon">
        </div>
      </div>
      <div class="col-2" v-if="incidents_list === 'active'">
        <div class="btn-group mx-4" role="group" aria-label="First group">
          <b-button variant="primary" @click="newIncident">
            New Incident <i class="bx bxs-plus-square ms-2"></i>
          </b-button>
        </div>
      </div>
      <div class="col-2" v-if="incidents_list === 'preplan'">
        <div class="btn-group mx-4" role="group" aria-label="First group">
          <b-button variant="primary" @click="newPreplan">
            New Preplan <i class="bx bxs-plus-square ms-2"></i>
          </b-button>
        </div>
      </div>
    </div>

    <table class="table align-middle table-nowrap table-hover">
      <thead class="table-light">
      <tr>
        <th class="align-middle">Name</th>
        <th class="align-middle">Location</th>
        <th class="align-middle">Date/Time</th>
        <th class="align-middle">Quick Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="incident in filtered_incidents" :key="incident.id" class="incident"
          @click="goToIncidentDetails(incident.id)">
        <td>
          <span>{{ incident.name }}</span>
          <b-badge v-if="incident.from_cad" class="mx-1" variant="success">
            CAD</b-badge>
          <b-badge v-if="incident.from_preplan && incident.from_cad" class="mx-1" variant="primary">
            CAD Preplan</b-badge>
          <b-badge v-if="incident.from_rave" class="mx-1" variant="secondary">
            Rave</b-badge>
          <b-badge v-if="incident.priority_incident" class="mx-1" variant="danger">
            High Priority</b-badge>
        </td>
        <td>{{ incident.location }}</td>
        <td>{{ incident.date }}
          {{ getFormattedTime(incident.time) }}
          {{ getFormattedTimeZone(incident.time_zone) }}
        </td>
        <td>
          <button class="btn btn-primary btn-sm btn-rounded">
            View Details
          </button>
        </td>
      </tr>
      </tbody>
      <!-- TODO Add pagination     -->
    </table>
  </div>
</template>

<script>
import timezoneDict from '@/components/timezoneDict';
import {
  authComputed, checklistsComputed, incidentsComputed, incidentsMethods, tagsComputed,
} from '@/state/helpers';
import {
  closeLoading,
  showLoading,
} from '@/components/widgets/swalUtils';

/**
 * Incidents listing component
 */
export default {
  data() {
    return {
      incidents: null,
      incidents_count: null,
      incidents_list: null,
      filtered_incidents: null,
      searchInput: '',
      time_zones: timezoneDict,
      month_names: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    };
  },
  computed: {
    ...authComputed,
    ...incidentsComputed,
    ...checklistsComputed,
    ...tagsComputed,
  },
  methods: {
    ...incidentsMethods,
    goToIncidentDetails(incidentId) {
      this.$router.push({ name: 'Incident Details', params: { incidentId } });
    },
    filterIncidents() {
      if (this.searchInput === '') {
        this.filtered_incidents = this.incidents;
      } else {
        this.filtered_incidents = this.incidents.filter((incident) => incident.name.toLowerCase()
          .includes(this.searchInput.toLowerCase())
            || incident.location.toLowerCase().includes(this.searchInput.toLowerCase()));
      }
    },
    newIncident() {
      // Redirect to view to create new incident.
    },
    newPreplan() {
      // Redirect to view to create new Preplan incident.
    },
    getFormattedTime(time) {
      if (time) {
        return time.replace(/:[0-9]\w$/g, '');
      }
      return '';
    },
    getFormattedTimeZone(timezone) {
      if (timezone) {
        if (timezone.length > 5) {
          return this.time_zones[timezone];
        }
        return timezone;
      }
      return '';
    },
  },
  async mounted() {
    [, , this.incidents_list] = this.$route.path.split('/');
    switch (this.incidents_list) {
      case 'active':
        if (!this.getActiveIncidents) {
          showLoading('Loading Active incidents...');
          await this.loadActiveIncidents({
            user: JSON.parse(this.currentUser),
          });
          closeLoading();
        }
        this.incidents = this.getActiveIncidents;
        break;
      case 'preplan':
        if (!this.getPreplanIncidents) {
          showLoading('Loading Preplan incidents...');
          await this.loadPreplanIncidents({
            user: JSON.parse(this.currentUser),
          });
          closeLoading();
        }
        this.incidents = this.getPreplanIncidents;
        break;
      case 'archived':
        if (!this.getArchivedIncidents) {
          showLoading('Loading Archived incidents...');
          await this.loadArchivedIncidents({
            user: JSON.parse(this.currentUser),
          });
          closeLoading();
        }
        this.incidents = this.getArchivedIncidents;
        break;
      default:
        console.log('No type found for Incident Listing.');
    }
    this.incidents_count = this.incidents.length;
    this.filtered_incidents = this.incidents;
  },
};
</script>

<style scoped>

.incident {
  cursor: pointer;
}

.form-control {
  width: 280px;
}

</style>
